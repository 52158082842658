.whlive-chat__form {
  background: var(--whlive-chat--control-background-color, #f9f9f9);
  border-top: 1px solid var(--whlive-chat--control-border-color, #e6e6e6);
  max-height: 60%;
  min-height: 60px;
  padding: 10px;
}

.whlive-chat__replyingto {
  padding-bottom: 10px;
}

.whlive-chat__replyingto-close {
  right: 10px;
  top: 10px;
}

.whlive-chat__input-resizer {
  background: var(--whlive-chat--input-background-color, #ffffff);
  border-width: var(--whlive-chat--input-border-width, 1px);
  border-style: solid;
  border-color: var(--whlive-chat--input-border-color, #e6e6e6);
  border-radius: var(--whlive-chat--input-border-radius, 20px);
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  padding: var(--whlive-chat--input-padding, var(--whlive-chat--input-padding-vertical, 9px)) calc(var(--whlive-chat--input-padding, var(--whlive-chat--input-padding-horizontal, 20px)) + 20px) var(--whlive-chat--input-padding, var(--whlive-chat--input-padding-vertical, 9px)) var(--whlive-chat--input-padding, var(--whlive-chat--input-padding-horizontal, 20px));
}

.whlive-chat__input,
.whlive-chat__input-resizer::after {
  border: none;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.whlive-chat__input {
  background: transparent;
  color: var(--whlive-chat--input-font-color, #000000);
  overflow: hidden;
}
.whlive-chat__input::placeholder {
  color: var(--whlive-chat--input-placeholder-color, #666666);
  opacity: 1;
}

.whlive-chat__submit {
  background: var(--whlive-main-color) url(arrow-up.svg) 50% 50% no-repeat;
  background-size: 40%;
  border: none;
  border-radius: 100%;
  bottom: 14px;
  height: 32px;
  right: 14px;
  width: 32px;
}

.whlive-chat__about {
  background: var(--whlive-chat--control-background-color, #f9f9f9);
  font-size: 11px;
  color: #3B3B3B;
  padding: 6px 15px;
  border-top: 1px solid var(--whlive-chat--control-border-color, #e6e6e6);
}
.whlive-chat__form:not([hidden]) + .whlive-chat__about {
  padding-top: 0px;
  border-top: none;
}
.whlive-chat__about::before {
  background-color: var(--whlive-icon-color, var(--whlive-main-color));
  content: "";
  display: inline-block;
  height: 12px;
  -webkit-mask: url(user.svg) 50% 50%/13px 12px no-repeat;
  mask: url(user.svg) 50% 50%/13px 12px no-repeat;
  width: 13px;
  vertical-align: middle;
}
.whlive-chat.whlive-chat--moderator .whlive-chat__about::before {
  -webkit-mask-image: url(moderator.svg);
  mask-image: url(moderator.svg);
}

.whlive-chat__myscreenname {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}