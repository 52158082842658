:root {
  --whlive-chat--transition-time: .1s;
}

.whlive-conversationlist {
  padding-bottom: var(--whlive-chat--list-padding-vertical, var(--whlive-chat--list-padding, 20px));
  padding-left: var(--whlive-chat--list-padding-horizontal, var(--whlive-chat--list-padding, 20px));
  padding-right: var(--whlive-chat--list-padding-horizontal, var(--whlive-chat--list-padding, 20px));
  padding-top: var(--whlive-chat--list-padding-vertical, var(--whlive-chat--list-padding, 20px));
}

.whlive-conversationlist__tabs,
.whlive-conversationlist__unread {
  color: var(--whlive-chat--list-header-font-color, #000000);
  font-size: var(--whlive-chat--list-header-font-size, 1.2em);
  font-weight: bold;
}

.whlive-conversationlist__tab {
  display: inline-block;
  opacity: 0.5;
  cursor: pointer;
}

.whlive-conversationlist__tab + .whlive-conversationlist__tab {
  margin-left: 15px;
}

.whlive-conversationlist__tab--active {
  opacity: 1;
  text-decoration: underline;
}

.whlive-chat__messagesarea {
  background: var(--whlive-chat--messages-background-color, transparent);
}

.whlive-chat__bubble {
  margin-left: var(--whlive-chat--bubble-margin-horizontal, var(--whlive-chat--bubble-margin, 5px));
  margin-right: var(--whlive-chat--bubble-margin-horizontal, var(--whlive-chat--bubble-margin, 5px));
  margin-top: var(--whlive-chat--bubble-margin-vertical, var(--whlive-chat--bubble-margin, 5px));
  max-width: var(--whlive-chat--bubble-max-width, 75%);
  width: var(--whlive-chat--bubble-width, auto);
}
.whlive-chat--questions .whlive-chat__bubble, .whlive-chat--1on1list .whlive-chat__bubble {
  max-width: var(--whlive-chat--bubble-max-width, none);
  width: var(--whlive-chat--bubble-width, calc(100% - var(--whlive-chat--bubble-margin-horizontal, var(--whlive-chat--bubble-margin, 5px)) - var(--whlive-chat--bubble-margin-horizontal, var(--whlive-chat--bubble-margin, 5px))));
}
.whlive-chat__bubble--sameuser {
  margin-top: var(--whlive-chat--bubble-margin-sameuser, var(--whlive-chat--bubble-margin-vertical, var(--whlive-chat--bubble-margin, 2px)));
}
.whlive-chat__bubble:last-child {
  margin-bottom: var(--whlive-chat--bubble-margin-vertical, var(--whlive-chat--bubble-margin, 5px));
}

.whlive-chat__message {
  z-index: 0;
}
.whlive-chat__message::after {
  background: var(--whlive-chat--bubble-background-color, #ffffff);
  border: var(--whlive-chat--bubble-border-width, 1px) solid var(--whlive-chat--bubble-border-color, #000000);
  border-radius: var(--whlive-chat--bubble-border-radius, 10px);
  content: "";
  display: block;
  grid-area: 1/1/-1/-1;
  z-index: -1;
}
.whlive-chat__bubble--isreply .whlive-chat__message::after {
  background: var(--whlive-chat--bubble-background-color-reply, var(--whlive-chat--bubble-background-color, #ffffff));
  border-color: var(--whlive-chat--bubble-border-color-reply, var(--whlive-chat--bubble-border-color, #000000));
}
.whlive-chat--questions .whlive-chat__bubble-reply .whlive-chat__message::after {
  background: var(--whlive-chat--bubble-background-color-reply, var(--whlive-chat--bubble-background-color, #ffffff));
  border-color: var(--whlive-chat--bubble-border-color-reply, var(--whlive-chat--bubble-border-color, #000000));
  border-bottom-left-radius: var(--whlive-chat--bubble-border-radius, 10px);
  border-bottom-right-radius: var(--whlive-chat--bubble-border-radius, 10px);
}
.whlive-chat__bubble--mine .whlive-chat__message::after {
  background: var(--whlive-chat--bubble-background-color-mine, var(--whlive-chat--bubble-background-color, #ffffff));
  border-color: var(--whlive-chat--bubble-border-color-mine, var(--whlive-chat--bubble-border-color, #000000));
}
.whlive-chat__bubble--mine .whlive-chat__bubble-reply .whlive-chat__message::after {
  border-color: var(--whlive-chat--bubble-border-color-mine, var(--whlive-chat--bubble-border-color, #000000));
}
.whlive-chat__bubble-original .whlive-chat__message::after {
  border-radius: var(--whlive-chat--bubble-border-radius, 10px);
  background: var(--whlive-chat--bubble-background-color-original, var(--whlive-chat--bubble-background-color, #ffffff));
}
.whlive-chat__bubble--isreply .whlive-chat__bubble-reply .whlive-chat__message::after, .whlive-chat--questions .whlive-chat__bubble--hasreply .whlive-chat__bubble-reply .whlive-chat__message::after {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.whlive-chat__bubble--isreply .whlive-chat__bubble-original .whlive-chat__message::after, .whlive-chat--questions .whlive-chat__bubble--hasreply .whlive-chat__bubble-original .whlive-chat__message::after {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.whlive-chat--avatar-outside-bubble .whlive-chat__message::after {
  grid-column-start: 2;
}

.whlive-chat__avatar {
  background: var(--whlive-chat--avatar-background-color, #cccccc);
  border-radius: var(--whlive-chat--avatar-border-radius, 100%);
  color: var(--whlive-chat--avatar-font-color, #000000);
  font-size: var(--whlive-chat--avatar-font-size, 16px);
  height: var(--whlive-chat--avatar-size, 32px);
  margin-bottom: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
  margin-left: calc(var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px)) / 2);
  margin-right: calc(var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px)) / 2);
  margin-top: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
  width: var(--whlive-chat--avatar-size, 32px);
}
.whlive-chat--avatar-outside-bubble .whlive-chat__avatar {
  margin-left: 0;
  margin-top: 0;
}

.whlive-chat__metadata {
  color: var(--whlive-chat--metadata-font-color, var(--whlive-chat--message-font-color, #666666));
  flex-direction: row;
  font-size: var(--whlive-chat--metadata-font-size, 0.8em);
  padding-bottom: var(--whlive-chat--bubble-padding-metadata, 0);
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-right: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-top: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
}
.whlive-chat--avatar-before-message .whlive-chat__metadata {
  padding-left: 0;
}
.whlive-chat--avatar-outside-bubble .whlive-chat__metadata, .whlive-chat--avatar-hide-mine .whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__metadata, .whlive-chat--avatar-hide-theirs .whlive-chat--groupchat .whlive-chat__bubble:not(.whlive-chat__bubble--mine) .whlive-chat__metadata {
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
}
.whlive-chat__bubble--sameuser .whlive-chat__metadata {
  padding-left: 0;
}
.whlive-chat--show-sameuser-metadata:not(.whlive-chat--avatar-before-message) .whlive-chat__bubble--sameuser .whlive-chat__metadata {
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
}

.whlive-chat__bubble-original .whlive-chat__metadata {
  color: var(--whlive-chat--metadata-font-color-original, var(--whlive-chat--metadata-font-color, var(--whlive-chat--message-font-color, #666666)));
}

.whlive-chat__name {
  font-weight: bold;
}
.whlive-chat__name:not(:empty) {
  margin-right: var(--whlive-chat--metadata-spacing, 5px);
}
html[dir=rtl] .whlive-chat__name:not(:empty) {
  margin-left: var(--whlive-chat--metadata-spacing, 5px);
  margin-right: 0;
}

.whlive-chat__icons:not(:empty) {
  margin-left: var(--whlive-chat--metadata-spacing, 5px);
}
html[dir=rtl] .whlive-chat__icons:not(:empty) {
  margin-left: 0;
  margin-right: var(--whlive-chat--metadata-spacing, 5px);
}

.whlive-chat__reply {
  cursor: pointer;
  display: inline-block;
}
.whlive-chat__reply::after {
  background: var(--whlive-icon-color, var(--whlive-main-color));
  content: "";
  display: inline-block;
  height: 12px;
  -webkit-mask: url(reply.svg) 50% 50%/13px 12px no-repeat;
  mask: url(reply.svg) 50% 50%/13px 12px no-repeat;
  width: 13px;
}

.whlive-chat__vote {
  cursor: pointer;
  display: inline-block;
}
.whlive-chat__vote::after {
  background: var(--whlive-icon-color, var(--whlive-main-color));
  content: "";
  display: inline-block;
  height: 12px;
  -webkit-mask: url(thumb.svg) 50% 50%/13px 12px no-repeat;
  mask: url(thumb.svg) 50% 50%/13px 12px no-repeat;
  width: 13px;
}

.whlive-chat__star {
  cursor: pointer;
  display: inline-block;
}
.whlive-chat__star::after {
  background: var(--whlive-icon-color, var(--whlive-main-color));
  content: "";
  display: inline-block;
  height: 12px;
  -webkit-mask: url(star.svg) 50% 50%/13px 12px no-repeat;
  mask: url(star.svg) 50% 50%/13px 12px no-repeat;
  width: 13px;
}

.whlive-chat__actions {
  height: 15px;
  min-width: 0;
  overflow: hidden;
  text-align: end;
  transition: width var(--whlive-chat--transition-time);
  width: 0;
}
.whlive-chat__actions::after {
  background: var(--whlive-icon-color, var(--whlive-main-color));
  content: "";
  display: inline-block;
  height: 15px;
  -webkit-mask: url(angle-down.svg) 50% 50%/20px 20px no-repeat;
  mask: url(angle-down.svg) 50% 50%/20px 20px no-repeat;
  opacity: 0;
  transition: opacity 0.2s;
  width: 15px;
}
.whlive-chat__bubble:hover .whlive-chat__actions, .whlive-chat__bubble.whlive-chat__bubble--actionsopened .whlive-chat__actions {
  width: 20px;
}
.whlive-chat__bubble:hover .whlive-chat__actions::after, .whlive-chat__bubble.whlive-chat__bubble--actionsopened .whlive-chat__actions::after {
  opacity: 1;
}

.whlive-chat__unread {
  align-self: center;
  background: var(--whlive-chat--unread-background-color, transparent);
  border-radius: var(--whlive-chat--unread-border-radius, 0.75em);
  color: var(--whlive-chat--unread-font-color, var(--whlive-chat--metadata-font-color, var(--whlive-chat--message-font-color, #666666)));
  font-size: var(--whlive-chat--unread-font-size, var(--whlive-chat--metadata-font-size, 0.8em));
  height: var(--whlive-chat--unread-size, 1.5em);
  line-height: var(--whlive-chat--unread-size, 1.5em);
  margin-bottom: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
  margin-right: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  margin-top: var(--whlive-chat--bubble-padding-metadata, 0);
  min-width: var(--whlive-chat--unread-size, 1.5em);
  padding: 0 0.25em;
  text-align: center;
}

.whlive-chat__title {
  color: var(--whlive-chat--title-font-color, var(--whlive-chat--metadata-font-color, var(--whlive-chat--message-font-color, #666666)));
  font-size: var(--whlive-chat--title-font-size, var(--whlive-chat--metadata-font-size, 0.8em));
  padding-bottom: var(--whlive-chat--bubble-padding-metadata, 0);
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-right: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  margin-top: calc(0px - var(--whlive-chat--bubble-padding-metadata, 0));
}
.whlive-chat--avatar-before-message .whlive-chat__title {
  padding-left: 0;
}
.whlive-chat--avatar-outside-bubble .whlive-chat__title {
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
}
.whlive-chat__bubble--sameuser .whlive-chat__title {
  padding-left: 0;
}
.whlive-chat--show-sameuser-metadata:not(.whlive-chat--avatar-before-message) .whlive-chat__bubble--sameuser .whlive-chat__title {
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
}

.whlive-chat__body {
  color: var(--whlive-chat--message-font-color, #333333);
  font-size: var(--whlive-chat--message-font-size, 1em);
  font-weight: var(--whlive-chat--message-font-weight, normal);
  padding-bottom: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-right: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-top: var(--whlive-chat--bubble-padding-metadata, 0);
}
.whlive-chat--avatar-before-message .whlive-chat__body {
  padding-left: 0;
}
.whlive-chat--avatar-outside-bubble .whlive-chat__body {
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
}
.whlive-chat__bubble--sameuser .whlive-chat__body {
  padding-right: 0;
}
.whlive-chat--show-sameuser-metadata .whlive-chat__bubble--sameuser .whlive-chat__body {
  padding-right: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
}
.whlive-chat__bubble--sameuser .whlive-chat__body {
  padding-top: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
}
.whlive-chat--show-sameuser-metadata .whlive-chat__bubble--sameuser .whlive-chat__body {
  padding-top: var(--whlive-chat--bubble-padding-metadata, 0);
}
.whlive-chat__body a {
  color: var(--whlive-chat--message-link-color, #0000cc);
}

.whlive-chat__status {
  margin-top: var(--whlive-chat--bubble-margin-vertical, var(--whlive-chat--bubble-margin, 5px));
  z-index: 0;
}
.whlive-chat__status::after {
  background: var(--whlive-chat--bubble-border-color, #000000);
  content: "";
  display: block;
  height: 1px;
  left: var(--whlive-chat--bubble-margin-horizontal, var(--whlive-chat--bubble-margin, 5px));
  position: absolute;
  right: var(--whlive-chat--bubble-margin-horizontal, var(--whlive-chat--bubble-margin, 5px));
  z-index: -1;
}

.whlive-chat__status:last-child {
  margin-bottom: var(--whlive-chat--bubble-margin-vertical, var(--whlive-chat--bubble-margin, 5px));
}

.whlive-chat__status .whlive-chat__text {
  background: var(--whlive-chat--bubble-background-color, #ffffff);
  border: var(--whlive-chat--bubble-border-width, 1px) solid var(--whlive-chat--bubble-border-color, #000000);
  border-radius: calc(var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px)) + var(--whlive-chat--message-font-size, 1em) / 2);
  color: var(--whlive-chat--message-font-color, #333333);
  font-size: var(--whlive-chat--message-font-size, 1em);
  font-weight: var(--whlive-chat--message-font-weight, normal);
  line-height: var(--whlive-chat--message-font-size, 1em);
  max-width: var(--whlive-chat--bubble-max-width, 75%);
  padding-bottom: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
  padding-left: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-right: var(--whlive-chat--bubble-padding-horizontal, var(--whlive-chat--bubble-padding, 7px));
  padding-top: var(--whlive-chat--bubble-padding-vertical, var(--whlive-chat--bubble-padding, 5px));
}

.whlive-chat__backtobottom {
  background: #777 url(angle-down.svg) 50% 50% no-repeat;
  background-size: 40%;
  border: none;
  border-radius: 100%;
  color: #ffffff;
  height: 32px;
  left: calc(50% - 16px);
  padding: 10px;
  transition: 200ms opacity;
  width: 32px;
}

.whlive-chat__controls {
  align-items: stretch;
  background: var(--whlive-chat--menu-background-color, #ffffff);
  border: var(--whlive-chat--menu-border-width, 1px) solid var(--whlive-chat--menu-border-color, #000000);
  border-radius: var(--whlive-chat--menu-border-radius, 3px);
  color: var(--whlive-chat--menu-font-color, #000000);
  font-size: var(--whlive-chat--menu-font-size, 1em);
  opacity: 0;
  transition: opacity var(--whlive-chat--transition-time);
}

.whlive-chat--controls .whlive-chat__controls {
  opacity: 1;
}

.whlive-chat__controls > span {
  padding: 5px 8px;
  transition: background var(--whlive-chat--transition-time), color var(--whlive-chat--transition-time);
}
.whlive-chat__controls > span:first-of-type {
  border-top-left-radius: var(--whlive-chat--menu-border-radius, 3px);
  border-top-right-radius: var(--whlive-chat--menu-border-radius, 3px);
}
.whlive-chat__controls > span:last-of-type {
  border-bottom-left-radius: var(--whlive-chat--menu-border-radius, 3px);
  border-bottom-right-radius: var(--whlive-chat--menu-border-radius, 3px);
}
.whlive-chat__controls > span:hover {
  background: var(--whlive-chat--menu-background-color-hover, #1975d2);
  color: var(--whlive-chat--menu-font-color-hover, #ffffff);
}