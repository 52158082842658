.whlive-chat__controls-container {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
}

.whlive-chat__controls {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  white-space: nowrap;
}

.whlive-chat__controls > span {
  cursor: pointer;
  display: inline-block;
}

.whlive-chat--controls::after {
  z-index: 1;
}

.whlive-chat--controls .whlive-chat__controls {
  pointer-events: auto;
  visibility: visible;
  z-index: 2; /* make sure it is at top of next comment incase of overlap */
}