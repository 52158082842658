.whlive-meetingframe {
  position: relative;
}

.whlive-meetingframe__participant {
  display: inline-block;
  width: 160px;
  height: 90px;
}

.whlive-meetingframe__participant__video {
  width: 100%;
  height: 100%;
}