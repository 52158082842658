.whlive-player {
  cursor: default;
  display: block;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.whlive-player--framecontrol .whlive-player__useroverlays {
  display: none;
}
.whlive-player:not(.whlive-player--framecontrol) .whlive-player__videoholder {
  pointer-events: none;
}

.whlive-player,
.whlive-player * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.whlive-player__playerarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.whlive-player__videoholder,
.whlive-player__useroverlays,
.whlive-player__interfaceholder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.whlive-player__position__curtime,
.whlive-player__position__duration,
.rangeSlider {
  display: none;
}

.whlive-player__videoelement {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 100%;
  height: 100%;
}

.whlive-player__videoelement:not([src]) {
  visibility: hidden;
}

.whlive-player__videocontainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.whlive-player__interfaceholder {
  pointer-events: none;
}

.whlive-player__interfaceholder > * {
  pointer-events: all;
}

/* debug second video */
.whlive-player__videocontainer:not(.whlive-player__videocontainer--active) {
  top: -200vh;
}

html.dompack--debug-vidlayout .whlive-player__videoelement {
  height: 50%;
}

html.dompack--debug-vidlayout .whlive-player__videocontainer:not(.whlive-player__videocontainer--active) {
  top: 50%;
}

.whlive-player:not(.whlive-player--hasvideo) .whlive-player__interfaceholder {
  display: none;
}

.whlive-player__stillcontainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.whlive-player__stillcontainer:not(.whlive-player__stillcontainer--active) {
  display: none;
}
.whlive-player__stillcontainer img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.whlive-player__stillcontainer iframe {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
}