.whlive-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--whlive-main-dialog-zindex);
}
.whlive-dialog::before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.whlive-dialog__positioning {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.whlive-dialog--theme-whlive .whlive-dialog__dialogbox {
  background: white;
  padding: 20px;
  border-radius: 4px;
  min-height: 200px;
  max-height: calc(100vh - 70px);
  max-width: calc(100vw - 70px);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.36);
  display: flex;
  flex-direction: column;
}
.whlive-dialog--theme-whlive .whlive-dialog__message {
  min-width: 300px;
  flex: 1;
}
.whlive-dialog--theme-whlive .whlive-dialog__buttons {
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: flex-end;
}