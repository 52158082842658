@charset "UTF-8";
.whlive-chat {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.whlive-chat, .whlive-chat * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.whlive-conversationlist {
  display: flex;
  flex-direction: column;
}

.whlive-chat--list-headers-hidden .whlive-conversationlist__tabs,
.whlive-chat--list-headers-hidden .whlive-conversationlist__unread {
  display: none;
}

.whlive-conversationlist__entries:not(.whlive-conversationlist__entries--unanswered) {
  flex: 1;
}

.whlive-chat__messagesarea {
  align-items: flex-start;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
}

.whlive-chat__messages {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.whlive-chat__bubble {
  flex: none;
}
.whlive-chat--groupchat .whlive-chat__bubble--mine {
  align-self: flex-end;
}
.whlive-chat--align-theirs-start .whlive-chat__bubble:not(.whlive-chat__bubble--mine) {
  align-self: flex-start;
}
.whlive-chat--align-theirs-end .whlive-chat__bubble:not(.whlive-chat__bubble--mine) {
  align-self: flex-end;
}
.whlive-chat--align-mine-start .whlive-chat__bubble--mine {
  align-self: flex-start;
}
.whlive-chat--align-mine-end .whlive-chat__bubble--mine {
  align-self: flex-end;
}

.whlive-chat__message {
  align-items: stretch;
  display: grid;
  grid-template-areas: "avatar metadata metadata" "avatar title    unread" "avatar body     unread";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto 1fr;
  position: relative;
}
.whlive-chat__bubble--sameuser .whlive-chat__message {
  grid-template-areas: "avatar body metadata";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
}
.whlive-chat--show-sameuser-metadata .whlive-chat__bubble--sameuser .whlive-chat__message {
  grid-template-areas: "avatar metadata metadata" "avatar title    unread" "avatar body     unread";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto 1fr;
}
.whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__message {
  text-align: end;
}
.whlive-chat--align-theirs-end.whlive-chat--align-content-follow .whlive-chat__bubble:not(.whlive-chat__bubble--mine) .whlive-chat__message, .whlive-chat--align-mine-end.whlive-chat--align-content-follow .whlive-chat__bubble--mine .whlive-chat__message {
  text-align: end;
}
.whlive-chat--align-content-natural .whlive-chat--groupchat .whlive-chat__bubble .whlive-chat__message, .whlive-chat--align-mine-start .whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__message {
  text-align: start;
}

.whlive-chat__avatar {
  align-items: center;
  display: none;
  flex: none;
  grid-area: avatar;
  justify-content: center;
}
.whlive-chat--avatar-before-message .whlive-chat__avatar {
  display: flex;
}
.whlive-chat--avatar-hide-mine .whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__avatar {
  display: none;
}
.whlive-chat--avatar-hide-theirs .whlive-chat--groupchat .whlive-chat__bubble:not(.whlive-chat__bubble--mine) .whlive-chat__avatar {
  display: none;
}
.whlive-chat--avatar-initials .whlive-chat__avatar::before {
  content: attr(data-whlive-initials);
}

.whlive-chat__metadata {
  display: flex;
  flex-direction: row;
  grid-area: metadata;
}
.whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__metadata {
  justify-content: flex-end;
}
.whlive-chat--align-theirs-end.whlive-chat--align-content-follow .whlive-chat__bubble:not(.whlive-chat__bubble--mine) .whlive-chat__metadata, .whlive-chat--align-mine-end.whlive-chat--align-content-follow .whlive-chat__bubble--mine .whlive-chat__metadata {
  justify-content: flex-end;
}
.whlive-chat--align-content-natural .whlive-chat--groupchat .whlive-chat__bubble .whlive-chat__metadata, .whlive-chat--align-mine-start .whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__metadata {
  justify-content: flex-start;
}

.whlive-chat__name {
  flex: none;
}
.whlive-chat__bubble--sameuser .whlive-chat__name {
  display: none;
}
.whlive-chat--show-sameuser-metadata .whlive-chat__bubble--sameuser .whlive-chat__name {
  display: block;
}

.whlive-chat__time {
  flex: 1;
}
.whlive-chat__bubble--sameuser .whlive-chat__time {
  display: none;
}
.whlive-chat--show-sameuser-metadata .whlive-chat__bubble--sameuser .whlive-chat__time {
  display: block;
}
.whlive-chat--align-time-end .whlive-chat__time, .whlive-chat--1on1list .whlive-chat__time {
  text-align: end;
}
.whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__time, .whlive-chat--align-theirs-end.whlive-chat--align-content-follow .whlive-chat__bubble:not(.whlive-chat__bubble--mine) .whlive-chat__time, .whlive-chat--align-mine-end.whlive-chat--align-content-follow .whlive-chat__bubble--mine .whlive-chat__time {
  flex: none;
}
.whlive-chat--align-content-natural .whlive-chat--groupchat .whlive-chat__bubble--mine .whlive-chat__time {
  flex: 1;
}

.whlive-chat__icons {
  flex: none;
}

.whlive-chat__actions {
  flex: none;
}

.whlive-chat__unread {
  display: none;
  grid-area: unread;
}
.whlive-chat--list-unread-counts .whlive-chat__unread:not(:empty) {
  display: block;
}

.whlive-chat__title {
  grid-area: title;
}
.whlive-chat__bubble--sameuser .whlive-chat__title {
  display: none;
}
.whlive-chat--show-sameuser-metadata .whlive-chat__bubble--sameuser .whlive-chat__title {
  display: block;
}

.whlive-chat__body {
  flex: none;
  grid-area: body;
  min-width: 0;
  position: relative;
}

.whlive-chat--1on1list .whlive-chat__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whlive-chat__status {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: none;
  justify-content: center;
}
.whlive-chat__status .whlive-chat__time {
  display: none;
}

.whlive-chat__backtobottom {
  bottom: 0;
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.whlive-chat--scrollingup .whlive-chat__backtobottom {
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
}

.whlive-chat__form {
  flex: none;
  overflow: auto;
  position: relative;
}

.whlive-chat__replyingto {
  display: none;
  flex-direction: column;
  max-width: 100%;
}
.whlive-chat__form--replying .whlive-chat__replyingto {
  display: inline-flex;
}

.whlive-chat__replyingto-close {
  cursor: pointer;
  position: absolute;
}

.whlive-chat__replyingto-reply::after {
  content: " ";
}

.whlive-chat__replyingto-user {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whlive-chat__replyingto-message {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whlive-chat__input-resizer {
  align-items: stretch;
  border-radius: 0 0 4px 4px;
  display: grid;
  position: relative;
  width: 100%;
}
.whlive-chat__input-resizer::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}

.whlive-chat__input,
.whlive-chat__input-resizer::after {
  font: inherit;
  grid-area: 2/1;
  resize: none;
  width: auto;
}

.whlive-chat__submit {
  cursor: pointer;
  position: absolute;
}

.whlive-chat.whlive-chat--moderator .whlive-chat__bubble > .whlive-chat__message,
.whlive-chat.whlive-chat--moderator .whlive-chat__bubble-reply > .whlive-chat__message {
  cursor: pointer;
}