:root {
  --whlive-main-color: #1975d2;
  --whlive-main-dialog-zindex: 99999998;
  --whlive-main-fullscreen-zindex: 99999999;
  --whlive-enablesound-bgcolor: rgba(0,0,0,.6);
}

.whlive-player--theme-whlive {
  outline: none;
  /* Video fullscreen button */
  /* Video islive indicator */
  /* Video mute control */
  /* Video volume control */
  /* Video position control */
}
.whlive-player--theme-whlive .whlive-player__enablesound {
  display: none;
  color: #fff;
  border-radius: 6px;
  background-color: var(--whlive-enablesound-bgcolor);
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  font-size: 18px;
  line-height: 20px;
  left: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  align-items: center;
  transition: background-color 0.3s;
}
.whlive-player--theme-whlive.whlive-player--muted .whlive-player__enablesound {
  display: flex;
}
.whlive-player--theme-whlive .whlive-player__enablesound__image {
  width: 40px;
  height: 40px;
  background: transparent url(volume-up.svg) 0 50% no-repeat;
  background-size: contain;
  flex: none;
}
.whlive-player--theme-whlive .whlive-player__enablesound__text {
  padding-left: 10px;
}
.whlive-player--theme-whlive .whlive-player__controlbar {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  bottom: 15px;
  content: "";
  height: 36px;
  left: 15px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 15px;
  transition: opacity 500ms;
  top: auto;
  width: auto;
}
.whlive-player--theme-whlive.whlive-player--showcontrols .whlive-player__controlbar {
  opacity: 1;
  pointer-events: auto;
}
.whlive-player--theme-whlive .whlive-player__playstate {
  display: none;
  position: absolute;
  left: 25px;
  bottom: 2px;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.3s;
}
.whlive-player--theme-whlive.whlive-player--paused .whlive-player__playstate, .whlive-player--theme-whlive.whlive-player--canplay .whlive-player__playstate, .whlive-player--theme-whlive.whlive-player--canpause .whlive-player__playstate {
  display: block;
}
.whlive-player--theme-whlive .whlive-player__playstate__image {
  width: 16px;
  height: 16px;
  background: transparent none 50% 50% no-repeat;
  background-size: contain;
  flex: none;
}
.whlive-player--theme-whlive.whlive-player--paused .whlive-player__playstate__image, .whlive-player--theme-whlive.whlive-player--canplay .whlive-player__playstate__image {
  background-image: url(play.svg);
}
.whlive-player--theme-whlive.whlive-player--canpause .whlive-player__playstate__image {
  background-image: url(pause.svg);
}
.whlive-player--theme-whlive .whlive-player__fullscreen {
  display: none;
  position: absolute;
  right: 15px;
  height: 16px;
  width: 16px;
  bottom: 10px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.whlive-player--theme-whlive .whlive-player__fullscreen__text {
  display: none;
}
.whlive-player--theme-whlive .whlive-player__fullscreen__image {
  width: 16px;
  height: 16px;
  background: transparent url(expand.svg) 50% 50% no-repeat;
  background-size: contain;
  flex: none;
}
.whlive-player--theme-whlive.whlive-player--isfullscreen .whlive-player__fullscreen__image {
  background-image: url(compress.svg);
}
.whlive-player--theme-whlive.whlive-player--canreqfs .whlive-player__fullscreen {
  display: block;
}
.whlive-player--theme-whlive.whlive-player--videoend:not(.whlive-player--isfullscreen) .whlive-player__fullscreen {
  pointer-events: none;
  opacity: 0.2;
}
.whlive-player--theme-whlive:not(.whlive-player--canreqfs):not(.whlive-player--isfullscreen) .whlive-player__position {
  right: 80px;
}
.whlive-player--theme-whlive:not(.whlive-player--canreqfs):not(.whlive-player--isfullscreen) .whlive-player__togglesound {
  right: 10px;
}
.whlive-player--theme-whlive:not(.whlive-player--canreqfs):not(.whlive-player--isfullscreen) .whlive-player__volume {
  right: 40px;
}
.whlive-player--theme-whlive .whlive-player__islive {
  position: absolute;
  left: 15px;
  height: 36px;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: flex-start;
}
.whlive-player--theme-whlive .whlive-player__islive__image::before {
  content: "";
  display: inline-block;
  background-color: #cf1912;
  border-radius: 50%;
  width: 7px;
  height: 7px;
}
.whlive-player--theme-whlive .whlive-player__islive__text::before {
  margin-left: 8px;
  content: "LIVE";
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  display: inline-block;
  color: #fff;
}
.whlive-player--theme-whlive.whlive-player--playing.whlive-player--islive .whlive-player__islive {
  display: flex;
}
.whlive-player--theme-whlive .whlive-player__togglesound {
  display: none;
  position: absolute;
  right: 40px;
  bottom: 0;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;
  height: 36px;
  width: 30px;
  z-index: 0;
  transition: opacity 0.2s;
}
.whlive-player--theme-whlive .whlive-player__togglesound::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url(volume-up.svg) 50% 50% no-repeat;
  background-size: 18px 18px;
  flex: none;
  left: -1px;
  top: 1;
  z-index: 1;
}
.whlive-player--theme-whlive.whlive-player--canvolume.whlive-player--canmute .whlive-player__togglesound:hover::before, .whlive-player--theme-whlive.whlive-player--canvolume.whlive-player--canmute .whlive-player__togglesound:focus::before, .whlive-player--theme-whlive.whlive-player--canvolume.whlive-player--canunmute .whlive-player__togglesound:hover::before, .whlive-player--theme-whlive.whlive-player--canvolume.whlive-player--canunmute .whlive-player__togglesound:focus::before {
  background-image: none;
}
.whlive-player--theme-whlive.whlive-player--muted .whlive-player__togglesound::before, .whlive-player--theme-whlive.whlive-player--canunmute .whlive-player__togglesound::before {
  background-image: url(volume-slash.svg);
  background-size: 20px 20px;
}
.whlive-player--theme-whlive.whlive-player--muted .whlive-player__togglesound, .whlive-player--theme-whlive.whlive-player--canmute .whlive-player__togglesound, .whlive-player--theme-whlive.whlive-player--canunmute .whlive-player__togglesound {
  display: block;
}
.whlive-player--theme-whlive.whlive-player--canmute .whlive-player__togglesound:hover::after, .whlive-player--theme-whlive.whlive-player--canmute .whlive-player__togglesound:focus::after {
  bottom: 0;
  content: "";
  display: block;
  height: 120px;
  left: 0;
  position: absolute;
  width: 100%;
}
.whlive-player--theme-whlive .whlive-player__volume {
  display: none;
  position: absolute;
  right: 70px;
  bottom: 0;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
  height: 36px;
  width: 0;
  z-index: 0;
}
.whlive-player--theme-whlive.whlive-player--canvolume .whlive-player__volume, .whlive-player--theme-whlive.whlive-player--muted .whlive-player__volume {
  display: block;
}
.whlive-player--theme-whlive .whlive-player__volume__slider {
  background-color: rgba(255, 255, 255, 0.7);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  bottom: 36px;
  display: block;
  height: 90px;
  left: -4px;
  opacity: 0;
  padding: 15px 15px 0 15px;
  pointer-events: none;
  position: absolute;
  transition: opacity 250ms;
  width: 36px;
}
.whlive-player--theme-whlive .whlive-player__volume__slider::after {
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url(volume-up-black.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  content: "";
  display: block;
  height: 36px;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: -36px;
  width: 36px;
}
.whlive-player--theme-whlive.whlive-player--canunmute .whlive-player__volume__slider::after {
  background-image: url(volume-slash-black.svg);
  background-size: 20px 20px;
}
.whlive-player--theme-whlive.whlive-player--canvolume .whlive-player__volume:hover .whlive-player__volume__slider, .whlive-player--theme-whlive.whlive-player--canvolume .whlive-player__volume:focus .whlive-player__volume__slider, .whlive-player--theme-whlive.whlive-player--canvolume .whlive-player__togglesound:hover + .whlive-player__volume .whlive-player__volume__slider, .whlive-player--theme-whlive.whlive-player--canvolume .whlive-player__togglesound:focus + .whlive-player__volume .whlive-player__volume__slider {
  opacity: 1;
  pointer-events: auto;
}
.whlive-player--theme-whlive .whlive-player__volume__slider .rangeSlider {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: block;
  height: 75px;
  position: relative;
  width: 5px;
}
.whlive-player--theme-whlive .whlive-player__volume__slider .rangeSlider__handle {
  background-color: #fff;
  border-radius: 100%;
  bottom: 0;
  display: block;
  height: 9px;
  left: -2px;
  position: absolute;
  width: 9px;
}
.whlive-player--theme-whlive .whlive-player__volume__slider .rangeSlider__fill {
  background-color: var(--whlive-volumeslider-color, var(--whlive-main-color));
  border-radius: 4px;
  bottom: 0;
  position: absolute;
  width: 5px;
}
.whlive-player--theme-whlive.whlive-player--canunmute .whlive-player__volume__slider .rangeSlider__fill {
  display: none;
}
.whlive-player--theme-whlive .whlive-player__position {
  display: none;
}
.whlive-player--theme-whlive:not(.whlive-player--islive) .whlive-player__position, .whlive-player--theme-whlive:not(.whlive-player--islive) .whlive-player__position__curtime, .whlive-player--theme-whlive:not(.whlive-player--islive) .whlive-player__position__duration {
  display: block;
}
.whlive-player--theme-whlive .whlive-player__position {
  position: absolute;
  left: 85px;
  right: 120px;
  bottom: 15px;
  height: 5px;
}
.whlive-player--theme-whlive .whlive-player__position__curtime, .whlive-player--theme-whlive .whlive-player__position__duration {
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: -5px;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
}
.whlive-player--theme-whlive .whlive-player__position__curtime {
  right: 100%;
  margin-right: 7px;
}
.whlive-player--theme-whlive .whlive-player__position__duration {
  left: 100%;
  margin-left: 7px;
}
.whlive-player--theme-whlive .whlive-player__position__slider {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
}
.whlive-player--theme-whlive .whlive-player__position__slider .rangeSlider {
  display: block;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}
.whlive-player--theme-whlive .whlive-player__position__slider .rangeSlider__handle {
  background-color: #fff;
  border-radius: 100%;
  height: 9px;
  top: calc(50% - 4.5px);
  opacity: 0;
  position: absolute;
  width: 9px;
}
.whlive-player--theme-whlive .whlive-player__position__slider .rangeSlider__fill {
  background-color: var(--whlive-main-color);
  border-radius: 4px;
  height: 5px;
  position: absolute;
}