.whlive-agentcontrol {
  background: #ffffff;
  color: #000000;
  display: grid;
  grid-template-areas: "list   header myteams" "list   chats  myteams";
  grid-template-columns: 400px 1fr 320px;
  grid-template-rows: auto 1fr auto;
}
@media (max-width: 1024px) {
  .whlive-agentcontrol {
    grid-template-columns: 200px 1fr 160px;
  }
}

.whlive-agentcontrol__list {
  grid-area: list;
  overflow: auto;
}

.whlive-agentcontrol__header {
  background: rgba(241, 241, 241, 0.9);
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  display: grid;
  grid-area: header;
  grid-template-areas: "avatar name" "avatar title";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  padding: 25px;
}

.whlive-agentcontrol__header-avatar {
  align-items: center;
  background-color: var(--whlive-chat--avatar-background-color);
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  font-size: 26px;
  grid-area: avatar;
  height: 61px;
  justify-content: center;
  margin-right: 20px;
  width: 61px;
}
.whlive-agentcontrol__header-avatar::before {
  content: attr(data-whlive-initials);
}

.whlive-agentcontrol__header-name {
  align-self: end;
  color: #000000;
  font-weight: 600;
  grid-area: name;
}

.whlive-agentcontrol__header-title {
  align-self: start;
  color: #8b8c8e;
  grid-area: title;
}
.whlive-agentcontrol__header-title::before {
  content: "#";
}

.whlive-agentcontrol__chats {
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  grid-area: chats;
  padding: 15px 15px 0;
}

.whlive-agentcontrol__teamscolumn {
  display: flex;
  flex-direction: column;
  grid-area: myteams;
}

.whlive-agentcontrol__status {
  border-bottom: 1px solid #eeeeee;
  display: grid;
  flex: none;
  grid-area: status;
  grid-template-areas: "name" "status";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: 20px;
}

.whlive-agentcontrol__status-name {
  font-size: var(--whlive-chat--list-header-font-size);
  font-weight: bold;
  align-self: center;
  grid-area: name;
  margin-bottom: 5px;
}

.whlive-agentcontrol__status-status {
  align-self: center;
  grid-area: status;
}

.whlive-agentcontrol__myteams {
  color: #171a1e;
  flex: 1;
  overflow: auto;
  padding: 0 20px 20px;
}
.whlive-agentcontrol__myteams h2 {
  font-size: var(--whlive-chat--list-header-font-size);
  margin-bottom: 24px;
  margin-top: 20px;
  position: relative;
}
.whlive-agentcontrol__myteams h2::after {
  background-color: #eeeeee;
  bottom: -10px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

.whlive-agentcontrol__teamtitle {
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
}
.whlive-agentcontrol__teamtitle:focus {
  outline: none;
}
.whlive-agentcontrol__teamtitle::-webkit-details-marker {
  display: none;
}
.whlive-agentcontrol__teamtitle::before {
  content: "\e90b";
  display: inline-block;
  position: absolute;
  left: -21px;
  top: 2px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "utlive-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
details[open] .whlive-agentcontrol__teamtitle::before {
  content: "\e909";
}
.whlive-agentcontrol__team:not(.whlive-agentcontrol__team--canaccept) .whlive-agentcontrol__teamtitle {
  opacity: 0.5;
}

.whlive-agentcontrol__teammembers {
  color: #484b4e;
  list-style: none;
  margin: 0 0 0 10px;
}

.whlive-agentcontrol__teammember {
  font-size: 14px;
  line-height: 22px;
  position: relative;
}
.whlive-agentcontrol__teammember::before {
  background-color: #ff0000;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 8px;
  left: -16px;
  position: absolute;
  top: 6px;
  width: 8px;
}
.whlive-agentcontrol__teammember--canaccept::before {
  background-color: #00ff4b;
}