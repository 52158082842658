.whlive-mock-fullscreen-element {
  position: fixed !important;
  z-index: var(--whlive-main-fullscreen-zindex) !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  display: block !important;
  max-width: none !important;
  max-height: none !important;
  background: #000000 !important;
}