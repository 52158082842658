.whlive-chat__settingspanel {
  position: absolute;
}
.whlive-chat:not(.whlive-chat--showsettings) .whlive-chat__settingspanel {
  display: none;
}

.whlive-chat__closesettings {
  cursor: pointer;
  position: absolute;
}

.whlive-chat__closesettings {
  right: 0px;
  top: 0px;
}

.whlive-chat__closesettings__control {
  display: inline-block;
  background: url(close.svg) 50% 50%/20px 20px no-repeat;
  height: 24px;
  width: 24px;
}

.whlive-chat__settingspanel {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  padding: 20px;
  right: 0;
  top: 0;
}