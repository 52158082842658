.whlive-videodebugger {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #222;
  display: block;
  width: 1005%;
  height: 100vh;
  position: relative;
  z-index: 0;
  color: #ffffff;
}

.whlive-player.whlive-player--debugmode > .whlive-player__playerarea {
  left: 50%;
  transform-origin: 0 0;
  transform: scale(0.5);
}