.whlive-chat__tabarea {
  padding: 8px 0;
  text-transform: uppercase;
  background: var(--whlive-chat--control-background-color, #f9f9f9);
  border-bottom: 1px solid var(--whlive-chat--control-border-color, #e6e6e6);
  line-height: 16px;
}

.whlive-chat__tabarea:not([hidden]) {
  display: flex;
}
.whlive-chat--1on1chat .whlive-chat__tabarea:not([hidden]) {
  display: none;
}

.whlive-chat__tab {
  margin-left: 15px;
  cursor: pointer;
  color: var(--whlive-chat--control-font-color, #3b3b3b);
  font-size: 11px;
  transition: color 200ms;
}

.whlive-chat__tab__text {
  vertical-align: middle;
  display: inline-block;
}

.whlive-chat__tab--active,
.whlive-chat__tab:hover {
  color: var(--whlive-chat--control-font-color-active, #1975d2);
}

.whlive-chat__tab--active .whlive-chat__tab__text {
  text-decoration: underline;
}

.whlive-chat__settings {
  margin-right: 15px;
}
.whlive-chat--1on1chat .whlive-chat__settings, .whlive-chat:not(.whlive-chat--moderator) .whlive-chat__settings {
  display: none;
}

.whlive-chat__settings__control {
  background-color: var(--whlive-icon-color, var(--whlive-main-color));
  -webkit-mask: url(cogwheel.svg) 50% 50%/12px 12px no-repeat;
  mask: url(cogwheel.svg) 50% 50%/12px 12px no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.whlive-chat__flexspacer {
  flex-grow: 1;
}